import React, { useEffect, useRef, useState } from 'react'
import { FaRedo, FaUndo } from 'react-icons/fa'
import { FiRefreshCcw } from 'react-icons/fi'
import OdontogramImg from '@/assets/images/odontograma-1.jpg'
import OdontogramImg2 from '@/assets/images/odontograma-2.png'

import { fabric } from 'fabric'

import s from './styles.module.scss'

const Odontogram = ({ canvasBase64, setCanvasBase64, name = 'canvas', field }) => {
  const [canvas, setCanvas] = useState(null)
  const [selectedColor, setSelectedColor] = useState('#3498db')
  const [selectedBrushWidth, setSelectedBrushWidth] = useState(3)
  const [historySteps, setHistorySteps] = useState([])
  const [captureEvent, setCaptureEvent] = useState(null)

  const ref = useRef(null)

  useEffect(() => {
    setCanvas(initCanvas())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (captureEvent !== null) {
      ref.current.click()
    }
  }, [captureEvent])

  useEffect(() => {
    if (captureEvent !== null) {
      saveData(captureEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captureEvent])

  const saveData = async (e) => {
    let json = canvas.toJSON()
    canvas.loadFromJSON(json, function () {
      canvas.renderAll()
    })

    let dataURL = canvas.toDataURL({
      format: 'image/png',
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    })
    setCanvasBase64(dataURL)
  }

  const initCanvas = () => {
    let canvi = new fabric.Canvas(name, {
      height: 360,
      width: 690,
      backgroundColor: '#FAFAFA',
      isDrawingMode: true,
      preserveObjectStacking: true,
    })
    canvi.freeDrawingBrush.color = selectedColor
    canvi.freeDrawingBrush.width = selectedBrushWidth

    fabric.Image.fromURL(field?.name === 'Odontograma' ? OdontogramImg : OdontogramImg2, (img) => {
      let oImg = img.set({ left: 0, top: 0, angle: 0, height: 400, lockUniScaling: true }).scale(0.9)
      canvi?.add(oImg)
      canvi?.renderAll()
    })

    canvi.on('path:created', function (e) {
      setCaptureEvent(e.path)
    })
    return canvi
  }

  const handleChangeColor = (canvi, color) => {
    canvi.freeDrawingBrush.color = color
    setSelectedColor(color)
    canvi.renderAll()
  }

  const handleChangeBrushWidth = (canvi, width) => {
    canvi.freeDrawingBrush.width = width
    setSelectedBrushWidth(width)
    canvi.renderAll()
  }

  const handleReset = () => {
    canvas._objects.splice(1)
    setHistorySteps([])
    canvas.renderAll()
  }

  const handleUndo = () => {
    if (canvas._objects.length > 1) {
      const currentSteps = [...historySteps, canvas._objects.pop()]
      setHistorySteps(currentSteps)
      canvas.renderAll()
    }
  }

  const handleRedo = () => {
    if (historySteps.length > 0) {
      setHistorySteps(historySteps.slice(0, -1))
      const redoHistorySteps = historySteps.slice().pop()
      canvas.add(redoHistorySteps)
      canvas.renderAll()
    }
  }

  return (
    <div className={s.signStore}>
      <div className={s.blockIcons}>
        <button className={s.icon} type="button" onClick={() => handleReset(canvas)}>
          <i>
            <FiRefreshCcw />
          </i>
        </button>
        <button className={s.icon} type="button" onClick={handleUndo}>
          <i>
            <FaUndo />
          </i>
        </button>
        <button className={s.icon} type="button" onClick={handleRedo}>
          <i>
            <FaRedo />
          </i>
        </button>
      </div>

      <div className={s.blockIcons}>
        <button
          className={canvas?.freeDrawingBrush?.width === 3 ? `${s.icon} ${s.selected} ` : `${s.icon}`}
          type="button"
          onClick={() => handleChangeBrushWidth(canvas, 3)}
        >
          <div className={s.dotVerySmall}></div>
        </button>
        <button
          className={canvas?.freeDrawingBrush?.width === 6 ? `${s.icon} ${s.selected} ` : `${s.icon}`}
          type="button"
          onClick={() => handleChangeBrushWidth(canvas, 6)}
        >
          <div className={s.dotSmall}></div>
        </button>
        <button
          className={canvas?.freeDrawingBrush?.width === 9 ? `${s.icon} ${s.selected} ` : `${s.icon}`}
          type="button"
          onClick={() => handleChangeBrushWidth(canvas, 9)}
        >
          <div className={s.dotMedium}></div>
        </button>
      </div>

      <div className={s.blockIcons}>
        <button
          id="color-blue"
          type="button"
          className={
            canvas?.freeDrawingBrush?.color === '#3498db'
              ? `${s.icon} ${s.colorBlue}  ${s.selected} `
              : `${s.icon} ${s.colorBlue}`
          }
          onClick={() => handleChangeColor(canvas, '#3498db')}
        ></button>
        <button
          id="color-green"
          type="button"
          className={
            canvas?.freeDrawingBrush?.color === '#2ecc71'
              ? `${s.icon} ${s.selected} ${s.colorGreen}`
              : `${s.icon} ${s.colorGreen}`
          }
          onClick={() => handleChangeColor(canvas, '#2ecc71')}
        ></button>
        <button
          id="color-red"
          type="button"
          className={
            canvas?.freeDrawingBrush?.color === '#e74c3c'
              ? `${s.icon} ${s.selected} ${s.colorRed}`
              : `${s.icon} ${s.colorRed}`
          }
          onClick={() => handleChangeColor(canvas, '#e74c3c')}
        ></button>
        <button
          id="color-black"
          type="button"
          className={
            canvas?.freeDrawingBrush?.color === '#1a232b'
              ? `${s.icon} ${s.selected} ${s.colorBlack}`
              : `${s.icon} ${s.colorBlack}`
          }
          onClick={() => handleChangeColor(canvas, '#1a232b')}
        ></button>
      </div>

      <canvas className={s.canvasContainer} id={name} />

      <p className={s.info}>Al guardar como Borrador u Original no se podrá editar más.</p>
      <button className={s.saveButton} type="button" ref={ref} onClick={(e) => saveData(e)}>
        Save
      </button>
    </div>
  )
}

export default Odontogram
