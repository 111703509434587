import React, { useState } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import ImageCircle from '@/components/imageCircle'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { useUploadImage } from '@/hooks/useUploadImage'
import { postDoctorCentres } from '@/store/doctorCentres/actions'
import { updateDoctorCentres } from '@/store/doctorCentres/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Button, Grid, Paper } from '@mui/material'

import s from './styles.module.scss'

const CardShowInformationSelected = ({
  entity,
  modifyEntity,
  deleteEntity,
  title,
  messageDelete,
  textContentExtraOptional,
}) => {
  const dispatch = useDispatch()
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const centreDetail = useSelector((state) => state.centres?.centreProfile)
  const [deleteImage, setDeleteImage] = useState(false)
  const { loadingImage, uploadImage } = useUploadImage()
  const { defaultCentre } = useUser()
  const { socket } = useSocket()

  const resendInvitation = () => {
    const isResendingInvitation = true
    dispatch(postDoctorCentres(entity, isResendingInvitation))
  }

  const deleteEntityImage = async () => {
    const auxEntity = {
      ...entity,
      image: '',
    }

    const updatedDoctorCentre = await dispatch(updateDoctorCentres(auxEntity))
    socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
  }

  return (
    <div>
      <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
        <Grid container>
          <Grid item sm={12} md={4}>
            <div className={s.divName}>
              <div className={s.divImage}>
                <ImageCircle
                  image={entity.image}
                  uploadImage={(e) => uploadImage(e, 'image', updateDoctorCentres, entity)}
                  loading={loadingImage}
                  className="uploadImageProfile"
                  id="patientImage"
                />
              </div>
              {entity.image && (
                <Button className={s.deleteEntityImage} onClick={() => setDeleteImage(true)}>
                  Eliminar foto
                </Button>
              )}

              <div className={s.name}>{`${capitalizeFirstLetters(entity?.firstname)} ${capitalizeFirstLetters(
                entity?.lastname,
              )}`}</div>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <div>
              {entity?.cellphone && (
                <p>
                  Teléfono Móvil: <b>{entity?.cellphone}</b>
                </p>
              )}
              {entity?.email && (
                <p>
                  Email: <b>{entity?.email}</b>
                </p>
              )}
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className={s.containerButtons}>
              <div>
                <Button fullWidth variant="contained" className={s.editButton} onClick={() => modifyEntity(entity)}>
                  Modificar
                </Button>
              </div>
              {!entity?.userActivation && (
                <div>
                  <Button
                    className={s.resendInvitation}
                    variant="contained"
                    fullWidth
                    onClick={() => resendInvitation()}
                  >
                    Reenviar Invitacion
                  </Button>
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  disableElevation
                  className={s.deleteButton}
                  onClick={() => setOpenAlertDelete(!openAlertDelete)}
                >
                  Eliminar
                </Button>

                <AlertDialogSlide
                  open={openAlertDelete}
                  setOpen={setOpenAlertDelete}
                  title={title}
                  textContent={messageDelete}
                  textContentExtraOptional={textContentExtraOptional}
                  textBackButton="Cancelar"
                  textConfirmationButton={'Confirmar'}
                  onClose={() => deleteEntity(entity)}
                />
                <AlertDialogSlide
                  title="Eliminar imagen"
                  open={deleteImage}
                  setOpen={setDeleteImage}
                  textContent={'¿Confirma que quiere eliminar la imagen?'}
                  textBackButton="Volver"
                  textConfirmationButton={'Confirmar'}
                  onClose={deleteEntityImage}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} className={s.divFooter}>
            {entity?.userActivation ? (
              <small className={s.iconYes}>
                <i>
                  <AiFillCheckCircle />
                </i>{' '}
                Tiene cuenta asociada a <span>{capitalizeFirstLetters(centreDetail?.name)}</span>
              </small>
            ) : (
              <small className={s.iconNo}>
                <i>
                  <AiFillCloseCircle />
                </i>{' '}
                No tiene cuenta asociada a <span>{capitalizeFirstLetters(centreDetail?.name)}</span>
              </small>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default CardShowInformationSelected
