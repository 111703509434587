import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AutoCompleteChedoc from '@/components/AutoCompleteChedoc'
import CaluButton from '@/components/styledComponents/CaluButton'
import {
  getCurrentcentreInsurances,
  postcentreInsurances,
  updatecentreInsurances,
} from '@/store/centreInsurances/actions'
import { getListInsurances } from '@/store/insurances/actions'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const TabInsurances = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [isLoading, setIsLoading] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const allInsurances = useSelector((state) => state.insurances.insurancesList)
  const centreInsurances = useSelector((state) => state.centreInsurances.centreInsurancesDetail)

  const { control, handleSubmit, setValue } = useForm()

  const onSubmit = async (data, e) => {
    e.preventDefault()
    setIsLoading(true)

    data.centre = centreDetail._id

    try {
      if (centreInsurances) {
        dispatch(updatecentreInsurances(data))
      } else {
        dispatch(postcentreInsurances(data))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onLoad = async () => {
    setIsLoading(true)
    await dispatch(getCurrentcentreInsurances(centreDetail?._id))
    setIsLoading(false)
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (centreInsurances) {
      Object.keys(centreInsurances).forEach((k) => {
        setValue(k, centreInsurances[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreInsurances])

  return (
    <div>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h5 className={s.titleSectionForm}>{t('insuranceLabel.P')}</h5>
          </Grid>

          <Grid item xs={12} className={s.infoInsurances}>
            <p>Selecciona {t('insuranceLabel.pa')} que aparecerán disponibles:</p>
          </Grid>

          <Grid item xs={12}>
            {isLoading ? (
              <div>Cargando</div>
            ) : (
              <AutoCompleteChedoc
                name="insurances"
                multiple={true}
                control={control}
                setValue={setValue}
                stateRedux={allInsurances}
                functionToDispatch={getListInsurances}
                inputLabel={`${t('insuranceLabel.S')}`}
                className={s.inputWidth98}
                noPaginate={true}
              />
            )}
          </Grid>
        </Grid>
        <div className={s.contButton}>
          <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </form>
    </div>
  )
}

export default TabInsurances
