export const formDefaultValues = {
  dni: '',
  lastname: '',
  firstname: '',
  email: '',
  repiteEmail: '',
  password: '',
  repitePassword: '',
  mobile: '',
  country: '',
  gender: 'female',
  insurance: { _id: '-', name: 'Particular' },
}
