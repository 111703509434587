import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import TitlePopUp from '@/components/TitlePopUp'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { weekDays } from '@/helpers/constants'
import { useWindowSize } from '@/hooks/useWindowSize'
import { resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { postMachine } from '@/store/machines/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import HelpIcon from '@mui/icons-material/Help'
import { DialogContent, DialogTitle, Grid, IconButton, StepLabel, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'

import dayjs from 'dayjs'
import AgendaName from './components/AgendaName'
import DoctorCentreAutocomplete from './components/DoctorCentreAutocomplete'
import ServiceAutocomplete from './components/ServiceAutocomplete'
import WorkTimeDuration from './components/WorkTimeDuration'

import s from './styles.module.scss'

const CreateNewAgenda = ({ open, setOpen }) => {
  const [activeStep, setActiveStep] = useState(0)
  const dispatch = useDispatch()
  const [keyEnter, setKeyEnter] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const resizeValue = useWindowSize()

  const { defaultCentre } = useUser()
  const { socket } = useSocket()
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      agendaName: '',
    },
  })

  const agendaName = watch('agendaName')
  const servicesSelected = watch('services')
  const doctorCentresSelected = watch('doctorCentres')
  const duration = watch('duration')

  useEffect(() => {
    if (keyEnter) {
      setTimeout(() => {
        setKeyEnter(false)
      }, 1000)
    }
  }, [keyEnter])

  const handleNext = () => {
    if (agendaName.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setKeyEnter(false)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const showHelpInfoTooltip = () => {
    if (activeStep === 0)
      return 'La agenda puede ser para un profesional, un equipo, una sala o para el uso que requieras. Puedes crear tantas agendas como desees.'
    if (activeStep === 1)
      return 'El servicio puede ser una consulta si es profesional, una clase si es un profesor, un corte de pelo si es peluquero, o lo que  decidas. Puedes crear tantos servicios como desees'
  }

  const helpIcon = () => {
    return (
      <Tooltip title={showHelpInfoTooltip()} placement="bottom-start">
        <IconButton size="small">
          <HelpIcon style={{ fontSize: '16px' }} />
        </IconButton>
      </Tooltip>
    )
  }

  const assingAgendaName = {
    name: 'Asignar nombre de agenda',
  }

  const createService = {
    name: 'Creación de servicio',
  }
  const steps = [assingAgendaName, createService]

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') setKeyEnter(true)
  }

  const onSubmit = async (dataForm, e) => {
    let buttonClicked = e?.nativeEvent?.submitter?.name
    if (buttonClicked !== 'submitNuevaAgenda') return
    mxp.track(mxp.events.agenda.newAgenda.created)
    e.preventDefault()
    if (keyEnter) return

    setLoadingButton(true)
    let workTimes = []

    try {
      let auxAgenda = {
        centre: defaultCentre,
        name: agendaName,
        onlineTurns: true,
      }

      for (let i = 1; i <= 5; i++) {
        const startTime = dayjs().day(i).hour(8).minute(0).format()
        const finishTime = dayjs().day(i).hour(18).minute(0).format()

        let obj = {
          frequency: { code: 0 },
          onlineTurns: true,
          weekDay: {
            code: i,
            name: convertValueToName(weekDays, i),
          },
          turnDuration: duration,
          startTime: startTime,
          finishTime: finishTime,
          services: servicesSelected,
          doctorCentres: doctorCentresSelected,
        }

        workTimes.push(obj)
      }

      auxAgenda.workTimes = workTimes
      const createdMachine = await dispatch(postMachine(auxAgenda))
      socket.emit('calendar_new_entity', `${defaultCentre}_calendar`, createdMachine)
      setLoadingButton(false)
      dispatch(resetSelectedDoctorCentre())
      handleClose()
    } catch (e) {
      console.log(e)
      setLoadingButton(false)
    }
  }

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth scroll="paper">
      <DialogTitle className={s.dialogTitle}>
        <TitlePopUp textTitle={'Creá tu agenda en dos pasos'} handleClose={handleClose} />
      </DialogTitle>
      <DialogContent className={`${s.dialogContent} animate__animated animate__fadeIn`}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => handleKeyDown(e)}>
          <Box sx={{ width: '100%' }}>
            <div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} className={s.boxContainer}>
                {resizeValue?.width > 576 ? (
                  <Stepper nonLinear activeStep={activeStep} className={s.stepper}>
                    {steps.map((label, index) => (
                      <Step key={label.name} completed={activeStep[index]} className={s.step}>
                        <StepButton color="inherit" className={s.stepName}>
                          {label.name}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <>
                    {activeStep === 0 ? (
                      <Stepper nonLinear className={s.stepper}>
                        <Step className={s.step} active={true}>
                          <StepLabel icon={1}>Asignar nombre de agenda</StepLabel>
                        </Step>
                      </Stepper>
                    ) : (
                      <Stepper nonLinear className={s.stepper}>
                        <Step className={s.step} active={true}>
                          <StepLabel icon={2}>Creación de servicio</StepLabel>
                        </Step>
                      </Stepper>
                    )}
                  </>
                )}

                {activeStep === 0 && (
                  <div className={s.mainContainer1}>
                    <AgendaName control={control} helpIconFunction={helpIcon} />
                    <div className={s.doctorCentreAutocomplete}>
                      <DoctorCentreAutocomplete control={control} setValue={setValue} />
                    </div>
                  </div>
                )}

                {activeStep === 1 && (
                  <Grid container className={s.gridContainer2}>
                    <Grid item xs={12}>
                      <>
                        <ServiceAutocomplete control={control} setValue={setValue} />
                        <div className={s.contWorkTimeDuration}>
                          <WorkTimeDuration control={control} />
                        </div>
                      </>
                    </Grid>
                    <Grid item xs={12} className={s.textInformation}>
                      Luego podrás modificar estos datos, agregar más servicios y personalizar la agenda como tú quieras
                    </Grid>
                  </Grid>
                )}

                <div className={s.backButton}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={() => handleBack()}
                    variant="outlined"
                    className={s.standardButton}
                  >
                    Atrás
                  </Button>

                  {activeStep === 0 && (
                    <Button
                      onClick={() => {
                        mxp.track(mxp.events.agenda.newAgenda.continue)
                        handleNext()
                      }}
                      className={
                        agendaName.length && doctorCentresSelected?.length ? s.avaiableContinueButton : s.standardButton
                      }
                      variant="outlined"
                      disabled={!agendaName.length || !doctorCentresSelected?.length}
                    >
                      Continuar
                    </Button>
                  )}

                  {activeStep === 1 && (
                    <LoadingSaveButton
                      isLoading={loadingButton}
                      textButton={'Crear agenda'}
                      className={servicesSelected && duration ? s.avaiableContinueButton : s.standardButton}
                      type="submit"
                      variant="outlined"
                      disabled={!servicesSelected?.length || !duration}
                      name="submitNuevaAgenda"
                    />
                  )}
                </div>
              </Box>
            </div>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewAgenda
