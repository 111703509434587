import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import InvoiceTurn from '@/components/InvoiceTurn'
import CaluButton from '@/components/styledComponents/CaluButton'
import { paymentMethods } from '@/helpers/constants'
import { extrasAmount } from '@/helpers/constants'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { getExtraAmountTypeText } from '@/utils/functions/getExtraAmountTypeText'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { treatmentDebt, treatmentPaymentAmount } from '@/utils/functions/treatmentAmounts'
import { Grid, MenuItem } from '@mui/material'

import dayjs from 'dayjs'

import s from '../styles.module.scss'

const TreatmentInfo = ({
  formInfo,
  isTreatment,
  amount,
  setIsAddingExtraAmount,
  setAmount,
  setDebt,
  auxTotalPayments,
}) => {
  const { t } = useTranslation('global')
  const extraAmount = formInfo?.extraAmount
  const [addExtraAmount, setAddExtraAmount] = useState(formInfo?.extraAmount ? true : false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const { field: fieldTypeExtraAmount } = useController({ name: 'typeExtraAmount' })
  const { field: fieldExtraAmountValue } = useController({ name: 'extraAmountValue' })
  const { field: fieldExtraAmountDescription } = useController({ name: 'extraAmountDescription' })

  const moneySign = handleSetMoneySign(country)

  useEffect(() => {
    if (extraAmount) {
      fieldTypeExtraAmount.onChange(formInfo?.extraAmount?.type)
      fieldExtraAmountValue.onChange(formInfo?.extraAmount?.value)
      fieldExtraAmountDescription.onChange(formInfo?.extraAmount?.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraAmount])

  useEffect(() => {
    if (addExtraAmount) {
      if (typeof fieldExtraAmountValue?.value !== 'string') return
      const extraAmountValue = fieldExtraAmountValue?.value === '' ? 0 : parseInt(fieldExtraAmountValue?.value)
      let initialAmountValue = formInfo?.amount
      let initialExtraAmountValue = formInfo?.extraAmount?.value

      if (fieldTypeExtraAmount?.value === 'plusCash') {
        let amountValueWithoutExtra = formInfo?.amount + initialExtraAmountValue
        const auxNewAmount = extraAmountValue > 0 ? amountValueWithoutExtra + extraAmountValue : initialAmountValue
        const auxNewDebt = extraAmountValue > 0 ? auxNewAmount - auxTotalPayments : formInfo.debt
        setAmount(auxNewAmount)
        setDebt(auxNewDebt)
      }

      if (fieldTypeExtraAmount?.value === 'plusPercentage') {
        let amountValueWithoutExtra = Math.round(initialAmountValue / (1 + initialExtraAmountValue / 100))
        const auxNewAmount =
          extraAmountValue > 0
            ? (amountValueWithoutExtra * extraAmountValue) / 100 + amountValueWithoutExtra
            : initialAmountValue
        const auxNewDebt = extraAmountValue > 0 ? auxNewAmount - auxTotalPayments : formInfo.debt
        setAmount(auxNewAmount)
        setDebt(auxNewDebt)
      }

      if (fieldTypeExtraAmount?.value === 'minusCash') {
        let amountValueWithoutExtra = formInfo?.amount + initialExtraAmountValue
        const auxNewAmount = extraAmountValue > 0 ? amountValueWithoutExtra - extraAmountValue : initialAmountValue
        const auxNewDebt = extraAmountValue > 0 ? auxNewAmount - auxTotalPayments : formInfo.debt
        setAmount(auxNewAmount)
        setDebt(auxNewDebt)
      }

      if (fieldTypeExtraAmount?.value === 'minusPercentage') {
        let amountValueWithoutExtra = Math.round(initialAmountValue / (1 - initialExtraAmountValue / 100))
        const auxNewAmount =
          extraAmountValue > 0
            ? amountValueWithoutExtra - (amountValueWithoutExtra * extraAmountValue) / 100
            : initialAmountValue
        const auxNewDebt = extraAmountValue > 0 ? auxNewAmount - auxTotalPayments : formInfo.debt
        setAmount(auxNewAmount)
        setDebt(auxNewDebt)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExtraAmount, fieldTypeExtraAmount.value, fieldExtraAmountValue.value])

  const showDate = (date) => {
    let formatedDate = isTreatment
      ? toCustomTz(date, undefined, false, 'dddd DD/MM/YYYY HH:mm')
      : toCustomTz(date, undefined, true, 'dddd DD/MM/YYYY HH:mm')
    return formatedDate
  }

  const handleAddExtraAmount = () => {
    setAddExtraAmount(true)
    setIsAddingExtraAmount(true)
  }

  return (
    <div className={s.containerForm}>
      <p className={s.infoPatientTreatment}>
        {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patient`)}:{' '}
        <span>
          {formInfo?.patientCentre
            ? `${formInfo?.patientCentre?.lastname?.toUpperCase()}, ${formInfo?.patientCentre?.firstname?.toUpperCase()}`
            : '--'}
        </span>{' '}
        , {isTreatment ? 'Detalle del tratamiento:' : 'fecha de turno'}{' '}
        <span>{`${isTreatment ? showDate(formInfo?.creationDate) : showDate(formInfo?.startHour)} hs`}</span>
      </p>

      {isTreatment ? (
        <>
          <Grid container className={s.infoTreatmentPayments}>
            <Grid item xs={12} md={6} className={s.infoTreatmentPaymentsItem}>
              <p>
                Monto pagado del tratamiento <span>{treatmentPaymentAmount(moneySign, formInfo)}</span>
              </p>
            </Grid>
            <Grid item xs={12} md={6} className={s.infoTreatmentPaymentsItem}>
              <p>
                Deuda del tratamiento: <span>{treatmentDebt(moneySign, formInfo)}</span>
              </p>
            </Grid>
          </Grid>

          <div className={s.divider}></div>

          {formInfo?.turns?.map((turn, i) => (
            <div key={turn?._id}>
              <p className={s.turnTreatment}>
                Sesión {i + 1}: <span>{`${dayjs(turn?.startHour).utc().format('dddd DD/MM/YYYY HH:mm')} hs`}</span>
              </p>
              <ul>
                {turn?.paymentHistory?.length === 0 ? (
                  <li>Sin pagos</li>
                ) : (
                  turn?.paymentHistory?.map((elem) => (
                    <li key={elem._id}>
                      <b className={s.turnTreatment}>{dayjs(elem?.date).utc().format('DD/MM/YYYY')}</b> {moneySign}
                      {elem?.amount} ({convertValueToName(paymentMethods, elem?.method)})
                    </li>
                  ))
                )}
              </ul>
              {formInfo?.isPaid && <p>Se ha marcado este tratamiento como pagado.</p>}
            </div>
          ))}
        </>
      ) : (
        <>
          <Grid container className={s.infoTreatmentPayments}>
            <Grid item xs={12} md={4} className={s.infoTreatmentPaymentsItem}>
              <p>
                Monto a pagar: <span>{`${moneySign}${amount}`}</span>
              </p>
            </Grid>
            <Grid item xs={12} md={4} className={s.infoTreatmentPaymentsItem}>
              <p>
                Monto pagado: <span>{`${moneySign}${auxTotalPayments}`}</span>
              </p>
            </Grid>
            <Grid item xs={12} md={4} className={s.infoTreatmentPaymentsItem}>
              <p>
                Deuda: <span>{`${moneySign}${amount - auxTotalPayments || 0}`}</span>
              </p>
            </Grid>
          </Grid>

          <Grid container className={s.extraAmountContainer}>
            {!addExtraAmount ? (
              <Grid item xs={12} className={s.buttonContainer}>
                <CaluButton size="small" inverted="true" onClick={handleAddExtraAmount} className={s.addButton}>
                  Agregar extra
                </CaluButton>
              </Grid>
            ) : (
              <>
                <Grid item xs={3.5}>
                  <SelectField name="typeExtraAmount" label="Tipo de extras o descuentos" fullWidth>
                    {extrasAmount?.map((elem) => (
                      <MenuItem key={elem.value} className={s.selectItem} value={elem.value}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={4}>
                  <CustomField
                    name="extraAmountValue"
                    label={getExtraAmountTypeText(fieldTypeExtraAmount)}
                    className={s.width98}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomField name="extraAmountDescription" label="Motivo" className={s.width98} />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      <InvoiceTurn />
    </div>
  )
}

export default TreatmentInfo
