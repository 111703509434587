import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageHearedTitle } from '@/components/pages'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { setCurrentCentre } from '@/controllers/centres'
import useValidateURLId from '@/hooks/useValidateURLId'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import { getCurrentCentre } from '@/store/centres/actions'
import { deleteDoctorAdded, getDoctorCentres, resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { deleteSecretaryAdded, getSecretaryCentre, resetSelectedSecretaryCentre } from '@/store/secretaryCentre/actions'
import Paper from '@mui/material/Paper'

import CardShowInformationSelected from './components/cardShowInformationSelected'
import ContentSidebar from './components/contentSidebar'
import DoctorCentresPopUp from './components/popUp/DoctorCentresPopUp'
import SecretaryCentrePopUp from './components/popUp/secretaryCentrePopUp'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const Administracion = ({ state }) => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const { defaultCentre } = useUser()

  const [openDoctorPopUp, setOpenDoctorPopUp] = useState(false)
  const [openSecretaryPopUp, setOpenSecretaryPopUp] = useState(false)
  const [input, setInput] = useState('')
  const [doctorCentresFiltered, setDoctorCentresFiltered] = useState([])
  const [secretaryCentreFiltered, setSecretaryCentreFiltered] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadingDoctorCentres, setLoadingDoctorCentres] = useState(true)
  const [loadingSecretaryCentres, setLoadingSecretaryCentres] = useState(true)

  const doctorCentres = useSelector((state) => state.doctorCentres?.doctorCentres)
  const doctorCentreSelected = useSelector((state) => state.doctorCentres?.doctorCentreSelected)
  const allSecretaries = useSelector((state) => state.secretaryCentre?.allSecretaries)
  const secretaryCentreSelected = useSelector((state) => state.secretaryCentre?.secretaryCentreSelected)

  const setInit = async () => {
    const currentCentre = await setCurrentCentre(centreID)
    dispatch(getCurrentCentre(currentCentre))
    await dispatch(getDoctorCentres(centreID))
    await dispatch(getSecretaryCentre(centreID))
    setIsLoading(false)
    setLoadingDoctorCentres(false)
    setLoadingSecretaryCentres(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreID])

  useEffect(() => {
    searchDoctorCentre()
    searchSecretaryCentre()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, doctorCentres, allSecretaries])

  const searchDoctorCentre = () => {
    const filteredDoctorCentres = doctorCentres?.filter(
      (doctor) =>
        doctor?.lastname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            input
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ) ||
        doctor?.firstname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            input
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ),
    )
    setDoctorCentresFiltered(filteredDoctorCentres)
  }

  const searchSecretaryCentre = () => {
    const filteredSecretaryCentre = allSecretaries?.filter(
      (secretary) =>
        secretary?.lastname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            input
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ) ||
        secretary?.firstname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            input
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ),
    )
    setSecretaryCentreFiltered(filteredSecretaryCentre)
  }

  const addNewDoctor = () => {
    dispatch(resetSelectedDoctorCentre())
    dispatch(resetSelectedSecretaryCentre())
    setOpenDoctorPopUp(!openDoctorPopUp)
  }

  const addNewSecretary = () => {
    dispatch(resetSelectedSecretaryCentre())
    dispatch(resetSelectedDoctorCentre())
    setOpenSecretaryPopUp(!openSecretaryPopUp)
  }

  const modifyDoctorCenter = () => {
    setOpenDoctorPopUp(!openDoctorPopUp)
  }

  const modifySecretaryCentre = () => {
    setOpenSecretaryPopUp(!openSecretaryPopUp)
  }

  const deleteDoctorCentre = async (entity) => {
    const deletedDoctorCentre = await dispatch(deleteDoctorAdded(entity._id))
    if (deletedDoctorCentre) {
      socket.emit('calendar_delete_entity', `${defaultCentre}_calendar`, entity)
    }
    dispatch(resetSelectedDoctorCentre())
  }

  const deleteSecretaryCentre = async (entity) => {
    const deletedSecretary = await dispatch(deleteSecretaryAdded(entity._id))
    if (deletedSecretary) {
      socket.emit('calendar_delete_entity', `${defaultCentre}_calendar`, entity)
    }
    dispatch(resetSelectedSecretaryCentre())
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar usuario'}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={'Cargar profesional'}
          buttonOnClick={addNewDoctor}
          buttonOnClick2={addNewSecretary}
          buttonLabel2={'Cargar asistente'}
          content={
            <ContentSidebar
              listItems={doctorCentresFiltered}
              listItems2={secretaryCentreFiltered}
              listTitle={'Profesionales'}
              listTitle2={'Asistentes'}
              loadingDoctorCentres={loadingDoctorCentres}
              loadingSecretaryCentres={loadingSecretaryCentres}
            />
          }
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText="Usuarios" />

          {doctorCentreSelected !== null && secretaryCentreSelected === null ? (
            <CardShowInformationSelected
              entity={doctorCentreSelected}
              modifyEntity={modifyDoctorCenter}
              deleteEntity={deleteDoctorCentre}
              title="Importante⚠️"
              messageDelete="Está a punto de eliminar al profesional."
              textContentExtraOptional="Verifica que el profesional no tenga citas o información relevante previo a eliminarlo."
            />
          ) : doctorCentreSelected === null && secretaryCentreSelected !== null ? (
            <CardShowInformationSelected
              entity={secretaryCentreSelected}
              modifyEntity={modifySecretaryCentre}
              deleteEntity={deleteSecretaryCentre}
              messageDelete={'Esta seguro que quiere eliminar este asistente?'}
            />
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p>Seleccione un perfil del listado para ver su información</p>
            </Paper>
          )}
        </div>

        <DoctorCentresPopUp
          entity={doctorCentreSelected}
          openDoctorPopUp={openDoctorPopUp}
          setOpenDoctorPopUp={setOpenDoctorPopUp}
        />
        <SecretaryCentrePopUp openSecretaryPopUp={openSecretaryPopUp} setOpenSecretaryPopUp={setOpenSecretaryPopUp} />
      </div>
    </Layout1>
  )
}

export default Administracion
