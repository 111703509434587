import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DateField } from '@/components/Form/customFields/DateField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import { CaluFormSelect } from '@/components/formComponents/customFields/CaluFormSelect'
import CaluButton from '@/components/styledComponents/CaluButton'
import { countryCodes, genders } from '@/helpers/constants'
import { getListInsurances } from '@/store/insurances/actions'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({
  isLoading = false,
  showErrorEmails,
  showErrorPasswords,
  requestError,
  setRequestError,
}) => {
  const { t } = useTranslation('global')

  const insurances = useSelector((state) => state.insurances.insurancesList)
  const insuranceList = [{ _id: '-', name: 'Particular' }, ...insurances]
  const [countryCode, setCountryCode] = useState('549')
  const [dniLabel, setDniLabel] = useState('DNI')
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const COUNTRY = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY

  const watchInsurance = useWatch({ name: 'insurance' })
  const watchCountry = useWatch({ name: 'country', defaultValue: COUNTRY })
  const watchEmail = useWatch({ name: 'email' })
  const watchPassword = useWatch({ name: 'password' })
  const watchDni = useWatch({ name: 'dni' })

  const { field: patientDni } = useController({ name: 'dni' })
  const { field: fieldCountry } = useController({ name: 'country' })

  useEffect(() => {
    if (patientDni.value.length === 15) {
      patientDni.onChange('')
    }
    if (COUNTRY) fieldCountry.onChange(COUNTRY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const countryCode = handleSetCountryCode(watchCountry)
    const dniLabel = handleSetDniLabel(watchCountry)
    if (countryCode) setCountryCode(countryCode)
    if (dniLabel) setDniLabel(dniLabel)
  }, [watchCountry])

  useEffect(() => {
    if (requestError) {
      setRequestError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDni, watchEmail, watchPassword])

  return (
    <div className={s.container}>
      <div className={s.loginTitleContainer}>
        <div className={s.loginTitle}>Crea tu cuenta en Calu</div>
      </div>
      <Grid container spacing={2} className={s.bodyCard}>
        <Grid item md={12} className={s.subtitle}>
          Datos personales
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText="Nombre(s) *" name="firstname" className={s.width98} autoFocus />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText="Apellido(s) *" name="lastname" className={s.width98} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText={`${dniLabel} *`} name="dni" className={s.width98} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput
            labelText="Celular *"
            name="mobile"
            type="number"
            className={s.width98}
            countryCode={countryCode}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CaluFormSelect
            items={sortArrayAlphabetically(countryCodes)}
            labelText="País"
            name="country"
            type="text"
            className={s.width98}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CaluFormSelect items={genders} labelText="Género" name="gender" type="text" className={s.width98} />
        </Grid>

        <Grid item xs={12} md={12} className={s.datePicker}>
          <DateField label="Fecha de nacimiento (DD/MM/YYYY)" name="birth" fullWidth className={s.width98} />
        </Grid>

        <Grid item md={12} className={s.subtitle}>
          Datos médicos
        </Grid>

        <Grid item xs={12} md={watchInsurance?._id === '-' || !watchInsurance ? 12 : 6}>
          <CaluFormAutoComplete
            isform={'true'}
            name="insurance"
            stateredux={insuranceList}
            functiontodispatch={getListInsurances}
            inputlabel={`${t('insuranceLabel.S')}`}
          />
        </Grid>

        {watchInsurance && watchInsurance?._id !== '-' && (
          <Grid item xs={12} md={6}>
            <CaluFormInput labelText="Número de afiliado" type="text" name={'policyNumbers'} className={s.width98} />
          </Grid>
        )}

        <Grid item md={12} className={s.subtitle}>
          Datos de ingreso
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText="Email *" type="text" name="email" className={s.width98} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText="Repite email *" name="repiteEmail" type="email" className={s.width98} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText="Contraseña *" name="password" type="password" className={s.width98} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaluFormInput labelText="Repite contraseña *" name="repitePassword" type="password" className={s.width98} />
        </Grid>

        {showErrorEmails ? (
          <div className={s.containerShowErrors}>
            <b>Los emails deben coincidir</b>
            <b>Vuelve a intentarlo o escribenos por el Chat para que te ayudemos</b>
          </div>
        ) : showErrorPasswords ? (
          <div className={s.containerShowErrors}>
            <b>Las contraseñas deben coincidir</b>
            <b>Vuelve a intentarlo o escribenos por el Chat para que te ayudemos</b>
          </div>
        ) : null}
        <div className={s.buttonContainer}>
          <CaluButton color="primary" type="submit" size="large" loading={isLoading} disabled={requestError}>
            Registrarme
          </CaluButton>
        </div>
      </Grid>
    </div>
  )
}
