import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { DateField } from '@/components/Form/customFields/DateField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import { countryCodes } from '@/helpers/constants'
import { getPatientTags } from '@/services/patientTags'
import { getListInsurances } from '@/store/insurances/actions'
import { deletePatientAdded } from '@/store/patientCentres/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { Grid, MenuItem } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose }) => {
  const { t } = useTranslation('global')
  const { defaultCentre } = useUser()
  const dispatch = useDispatch()

  const [allPatientTags, setAllPatientTags] = useState([])
  const [countryCode, setCountryCode] = useState(54)
  const [dniLabel, setDniLabel] = useState('DNI')
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const patientSelected = useSelector((state) => state.patientCentres?.patientSelected)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { insurancesList } = useSelector((state) => state.insurances)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const watchInsurances = useWatch({ name: 'insurances' })
  const watchCountry = useWatch({ name: 'country' })
  const { field: patientDni } = useController({ name: 'dni' })
  const { field: fieldCountry } = useController({ name: 'country' })

  useEffect(() => {
    getPatientTags(defaultCentre).then((patientTags) => setAllPatientTags(patientTags))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (patientDni.value.length === 15) {
      patientDni.onChange('')
    }
    if (watchCountry) fieldCountry.onChange(watchCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const countryCode = handleSetCountryCode(watchCountry)
    const dniLabel = handleSetDniLabel(watchCountry)
    if (countryCode) setCountryCode(countryCode)
    if (dniLabel) setDniLabel(dniLabel)
  }, [watchCountry])

  const deletePatient = () => {
    dispatch(deletePatientAdded(patientSelected._id))
    setOpenAlertDelete(false)
    onClose()
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CustomField label={dniLabel} name="dni" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Apellido*" name="lastname" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Nombre*" name="firstname" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Email" name="email" type="email" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <b style={{ marginRight: '5px', marginTop: '10px' }}>{`+${countryCode}`}</b>
            <CustomField label="Celular*" name="mobile" type="number" fullWidth />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Teléfono fijo" name="telephone" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            label={`Nota del ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
            name="note"
            type="text"
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField label="País" name="country" type="text" fullWidth>
            {sortArrayAlphabetically(countryCodes).map((code) => (
              <MenuItem key={code.value} value={code.value}>
                {code.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Dirección" name="address" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Ciudad" name="city" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateField label="Fecha de nacimiento (DD/MM/YYYY)" name="birth" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            label="Bloquear turnos online"
            name="blockOnlineTurns"
            checkBoxColor={null}
            checkedLabel="Bloqueado"
            uncheckedLabel="Habilitado"
            inverted
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField label="Género" name="gender" type="text" fullWidth>
            <MenuItem value={'female'}>Femenino</MenuItem>
            <MenuItem value={'male'}>Masculino</MenuItem>
            <MenuItem value={'other'}>Prefiero no decirlo</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <CaluFormAutoComplete
            name="insurances"
            multiple={true}
            stateredux={insurancesList}
            functiontodispatch={getListInsurances}
            inputlabel={`${t('insuranceLabel.S')}`}
          />
        </Grid>
        {watchInsurances?.map((insurance) => (
          <Grid key={insurance._id} container spacing={2} style={{ margin: '0px' }}>
            <Grid item xs={12} md={6}>
              <CustomField
                label={`Información adicional del plan ${insurance.name}`}
                name={`insurancePlan[${insurance._id}]`}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomField
                label={`Número de afiliado ${insurance.name}`}
                name={`policyNumbers[${insurance._id}]`}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <AutoCompleteField label="Etiquetas" name="patientTags" options={allPatientTags} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField label="Cómo llegó a conocernos" name="referrer" type="text" fullWidth>
            <MenuItem value={'referido'}>Referido</MenuItem>
            <MenuItem value={'google'}>Google</MenuItem>
            <MenuItem value={'instagram'}>Instagram</MenuItem>
            <MenuItem value={'facebook'}>Facebook</MenuItem>
            <MenuItem value={'publicidad'}>Publicidad</MenuItem>
          </SelectField>
        </Grid>
        {patientSelected && (
          <Grid item xs={12}>
            <small className={s.identifier}>{`IDENTIFICADOR ${patientSelected?._id.slice(-8)}`}</small>
          </Grid>
        )}

        <Grid item xs={12} className={s.containerButtonsForm}>
          {patientSelected && entityInLocalStorage?.role !== 'secretary' && (
            <>
              <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                title={`Eliminar ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={`¿Está seguro que quiere eliminar este ${t(
                  `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
                )}?`}
                textContentExtraOptional="Esta acción no se podrá deshacer."
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={deletePatient}
              />
            </>
          )}

          <CaluButton color="primary" type="submit" size="medium" loading={isLoading} disabled={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
