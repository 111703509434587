import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { paymentStatus } from '@/helpers/constants'
import { SUBSCRIPTION_PAYMENT_SELECTED } from '@/store/subscriptionPayments'
import { handlePaginationChangeSubscriptionPayments } from '@/store/subscriptionPayments/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckIcon from '@mui/icons-material/Check'
import DownloadIcon from '@mui/icons-material/Download'
import HelpIcon from '@mui/icons-material/Help'
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import AttachedPopUp from './AttachedPopUp'

import '@/assets/styles/_colors.module.scss'
import s from './styles.module.scss'

const Table = () => {
  const dispatch = useDispatch()
  const [openAttachedPopUp, setOpenAttachedPopUp] = useState(false)

  const { allSubscriptionPayments, paginationData, isLoading, selectedPayment } = useSelector(
    (state) => state?.subscriptionPayments,
  )

  const { limit, totalDocs, page } = paginationData || {}

  const pendingColor = '#ffea8a'
  const paidColor = '#63d2bc'
  const reviewColor = '#7fd5f4'

  const helpIcon = () => {
    return (
      <Tooltip title={'El estado de los pagos pueden tardar hasta 24h en actualizarse'} placement="bottom-start">
        <IconButton size="small">
          <HelpIcon style={{ fontSize: '16px' }} />
        </IconButton>
      </Tooltip>
    )
  }

  const getChipProps = (params) => {
    if (params?.row?.raw?.paymentStatus === 'pending') {
      return {
        label: params.value,
        style: {
          backgroundColor: pendingColor,
        },
      }
    }

    if (['paid', 'partiallyPaid'].includes(params?.row?.raw?.paymentStatus)) {
      return {
        label: params.value,
        style: {
          backgroundColor: paidColor,
        },
      }
    }

    if (params?.row?.raw?.paymentStatus === 'review') {
      return {
        label: params.value,
        style: {
          backgroundColor: reviewColor,
        },
      }
    }
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'period',
      headerName: 'Periodo',
      minWidth: 100,
      cellClassName: 'periodStyle',
      ...stylesColumns,
    },
    {
      field: 'amount',
      headerName: 'Monto',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'dueDate',
      headerName: 'Vencimiento',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'status',
      headerName: 'Estado',
      minWidth: 190,
      renderCell: (params) => {
        if (params?.row?.raw?.paymentStatus) {
          return (
            <div>
              <Chip variant="filled" size="small" {...getChipProps(params)} />
              {['pending', 'review'].includes(params?.row?.raw?.paymentStatus) && (
                <span className={s.iconStep1}>{helpIcon()}</span>
              )}
            </div>
          )
        }
      },
      ...stylesColumns,
    },
    {
      field: 'paymentDate',
      headerName: 'Fecha pago',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: 'Comprobantes',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <div>
          {params?.row?.raw?.subscriptionPaymentType !== 'mercadoPagoSubscription' && (
            <Tooltip title="Adjuntar comprobante">
              <Button
                onClick={() => handleClickEdit(params?.row)}
                className={s.rowButton}
                tabIndex={params.hasFocus ? 0 : -1}
              >
                {params?.row?.raw?.attached?.length > 0 ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <AttachFileIcon fontSize="small" />
                )}
              </Button>
            </Tooltip>
          )}

          {params?.row?.raw?.receipt?.length > 0 && (
            <Tooltip title="Descargar factura">
              <Button
                onClick={() => handleClickDownloadReceipt(params?.row?.raw?.receipt)}
                className={s.rowButton}
                tabIndex={params.hasFocus ? 0 : -1}
              >
                {<DownloadIcon fontSize="small" />}
              </Button>
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  const rows = allSubscriptionPayments?.map((entry) => {
    return {
      id: entry?._id,
      period: toCustomTz(entry?.period, undefined, true, 'MMMM YYYY').toUpperCase(),
      amount: `${entry?.currency === 'ARS' || 'ARG' ? '$' : 'USD'} ${entry?.amount}`,
      dueDate: toCustomTz(entry?.dueDate, undefined, true, 'YYYY-MM-DD'),
      status: convertValueToName(paymentStatus, entry?.paymentStatus),
      paymentDate: entry?.paymentDate ? toCustomTz(entry?.paymentDate, undefined, true, 'YYYY-MM-DD') : '-',
      raw: entry,
    }
  })

  const handleClickEdit = (data) => {
    dispatch(SUBSCRIPTION_PAYMENT_SELECTED(data))
    setOpenAttachedPopUp(true)
  }

  const handleClickDownloadReceipt = (data) => {
    if (data?.length === 0) return
    window.open(data[0]?.url, '_blank')
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeSubscriptionPayments(page + 1, pageSize))
  }

  return (
    <div className={s.tableContainer}>
      {allSubscriptionPayments?.length === 0 ? (
        <p>Próximamente aquí verás los pagos realizados</p>
      ) : (
        <Box
          sx={{
            px: 0,
            '& .header': {
              fontSize: '14px',
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .centreName': {
              fontWeight: 'bold',
            },
            '& .periodStyle': {
              fontWeight: '600',
            },
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: limit,
                },
              },
            }}
            disableColumnMenu={true}
            disableColumnReorder={true}
            rows={rows || []}
            rowCount={totalDocs || 0}
            loading={isLoading}
            columns={columns}
            paginationMode="server"
            pagination
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[30, 50, 100]}
            disableRowSelectionOnClick={true}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            slotProps={{
              pagination: {
                labelRowsPerPage: 'Entradas por página:',
                labelDisplayedRows: () =>
                  `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
              },
            }}
            sx={{ fontSize: '13px', marginBottom: '20px' }}
            rowHeight={45}
            autoHeight={true}
            sortModel={[{ field: 'dueDate', sort: 'desc' }]}
          />
        </Box>
      )}

      {openAttachedPopUp && (
        <AttachedPopUp open={openAttachedPopUp} setOpen={setOpenAttachedPopUp} dataVoucher={selectedPayment} />
      )}
    </div>
  )
}

export default Table
