import {
  addNewNotification,
  deleteNotification,
  getNotifications,
  getNotificationsReads,
  postNotificationRead,
  putNotification,
} from '@/services/notifications'
import {
  GET_NOTIFICATION_READS,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  NOTIFICATION_CREATED,
  NOTIFICATION_DELETE,
  NOTIFICATION_SELECTED,
  NOTIFICATION_UPDATED,
  RESET_NOTIFICATION_SELECTED,
  SAVE_FILTERED_NOTIFICATIONS,
} from '@/store/notifications'
import { showToast } from '@/utils/toast'

const getUserNotifications = (params) => async (dispatch) => {
  try {
    const notifications = await getNotifications(params)
    dispatch(GET_NOTIFICATIONS(notifications))
    let notificationsReads = await getNotificationsReads()
    dispatch(GET_NOTIFICATION_READS(notificationsReads))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const saveFilteredUserNotifications = (allFilteredNotifications) => (dispatch) => {
  dispatch(SAVE_FILTERED_NOTIFICATIONS(allFilteredNotifications))
}

const markNotificationAsRead = (notification) => async (dispatch) => {
  try {
    const notificationRead = await postNotificationRead(notification)
    dispatch(MARK_NOTIFICATION_AS_READ(notificationRead))
    showToast('Notificación marcada como leída', 'success', 1000)
  } catch (error) {
    showToast('no se pudieron actualizar las notificaciones', 'error')
    const { message } = error
    console.log(message)
  }
}

const postNotification = (data) => async (dispatch) => {
  const users = data.users
  try {
    const newNotification = await addNewNotification(data)
    newNotification.users = users
    dispatch(NOTIFICATION_CREATED(newNotification))
    showToast('Notificación agregada correctamente', 'success')

    return newNotification
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const updateNotification = (data) => async (dispatch) => {
  try {
    const notificationUpdated = await putNotification(data)
    dispatch(NOTIFICATION_UPDATED(notificationUpdated))

    if (notificationUpdated) {
      showToast('Notificación actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const deleteNotificationAdded = (id) => async (dispatch) => {
  try {
    await deleteNotification(id)
    dispatch(NOTIFICATION_DELETE(id))
    showToast('Notificación Eliminada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedNotification = (notification) => async (dispatch) => {
  try {
    dispatch(NOTIFICATION_SELECTED(notification))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedNotification = () => async (dispatch) => {
  try {
    dispatch(RESET_NOTIFICATION_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteNotificationAdded,
  getUserNotifications,
  markNotificationAsRead,
  postNotification,
  resetSelectedNotification,
  saveFilteredUserNotifications,
  selectedNotification,
  updateNotification,
}
