import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentCentre } from '@/controllers/centres'
import { loginUser } from '@/services/auth'
import { fetchDoctorCentre } from '@/services/doctorCentres'
import { fetchSingleSecretaryCentre } from '@/services/secretaryCentre/index'
import { fetchUserMe, postUser, putUser } from '@/services/users'
import { getCurrentCentre } from '@/store/centres/actions'
import { getDoctorProfile } from '@/store/doctorProfile/actions'
import { getUserNotifications } from '@/store/notifications/actions'
import { resetApp } from '@/store/resetApp'
import { handleSetUsersProfileConnected } from '@/store/users/actions'
import { handleDetectProfileConnected } from '@/utils/functions/handleDetectProfileConnected'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'

import { jwtDecode } from 'jwt-decode'

// @ts-ignore
const UserContext = createContext()

export default function UserProvider(props) {
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const [defaultCentre, setDefaultCentre] = useState('')
  const entityInLocalStorage = localStorage.getItem('selectedEntity')
  const parsedEntityInLocalStorage = JSON.parse(entityInLocalStorage)

  useEffect(() => {
    const userProfile = handleDetectProfileConnected(user, defaultCentre, parsedEntityInLocalStorage)

    if (userProfile) {
      dispatch(handleSetUsersProfileConnected(userProfile))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, defaultCentre, parsedEntityInLocalStorage, dispatch])

  const isAuthTokenValid = () => {
    const access_token = localStorage.getItem('token')
    if (!access_token) {
      localStorage.removeItem('selectedEntity')
      setUser(false)
      setDefaultCentre('')
      return false
    }

    try {
      const decoded = jwtDecode(access_token)
      const currentTime = Date.now() / 1000
      if (decoded?.exp < currentTime) {
        localStorage.removeItem('token')
        localStorage.removeItem('selectedEntity')
        setUser(false)
        setDefaultCentre('')
        return false
      }
      return true
    } catch (error) {
      localStorage.removeItem('token')
      localStorage.removeItem('selectedEntity')
      setUser(false)
      setDefaultCentre('')
      return false
    }
  }

  useEffect(() => {
    if (isAuthTokenValid()) {
      getUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const orderRolesByPriority = async (user) => {
    const isAdmin = user?.roles?.includes('admin')
    const hasCentres = user?.roles?.includes('centre') && user.centres?.length
    const hasDoctorCentres = user.roles?.includes('doctor') && user.doctorCentres?.length
    const hasSecretary = user?.roles?.includes('secretary') && user.secretaryCentres?.length
    const hasPatient = user.roles?.includes('patient') && user.patient
    let entity

    if (isAdmin) {
      const { pathname = '' } = window?.location

      const centreId = pathname.split('/').slice(-1)[0]
      if (centreId.length === 24) {
        entity = {
          _id: centreId,
          role: 'admin',
        }
        const selectedCentre = JSON.stringify(entity)

        return localStorage.setItem('selectedEntity', selectedCentre)
      }

      return localStorage.setItem(
        'selectedEntity',
        JSON.stringify({
          role: 'admin',
        }),
      )
    }

    if (hasCentres) {
      entity = {
        _id: user?.centres[0]._id, //CentreId
        role: 'centre',
      }
      const centre = JSON.stringify(entity)
      return localStorage.setItem('selectedEntity', centre)
    }

    if (hasSecretary) {
      entity = {
        _id: user.secretaryCentres[0].centre._id, //CentreId
        secretaryCentreId: user.secretaryCentres[0]._id,
        role: 'secretary',
      }
      const secretary = JSON.stringify(entity)
      return localStorage.setItem('selectedEntity', secretary)
    }

    if (hasDoctorCentres) {
      entity = {
        _id: user.doctorCentres[0].centre._id, //CentreId
        doctorCentreId: user.doctorCentres[0]._id,
        role: 'doctor',
      }
      const doctorCentres = JSON.stringify(entity)
      return localStorage.setItem('selectedEntity', doctorCentres)
    }

    if (hasPatient) {
      entity = {
        _id: user.patient._id,
        role: 'patient',
      }
      const patient = JSON.stringify(entity)
      return localStorage.setItem('selectedEntity', patient)
    }
  }

  const syncUser = async () => {
    const getSelectedEntityInLocalStorage = localStorage.getItem('selectedEntity')
    const entity = JSON.parse(getSelectedEntityInLocalStorage) || {}

    if (entity?.role === 'admin') {
      setDefaultCentre(entity?._id)
      const currentCentre = await setCurrentCentre(entity?._id)
      dispatch(getCurrentCentre(currentCentre))
    }
  }

  const getUser = async () => {
    try {
      const user = await fetchUserMe()

      if (!entityInLocalStorage) {
        await orderRolesByPriority(user)
      }

      const getSelectedEntityInLocalStorage = localStorage.getItem('selectedEntity')
      const entity = JSON.parse(getSelectedEntityInLocalStorage) || {}

      if (
        entity?.role !== 'admin' &&
        dispatch(
          getUserNotifications({
            role: entity?.role,
          }),
        )
      )
        if (entity?.role === 'secretary' && user?.secretaryCentres.length) {
          const secretaryCentre = await fetchSingleSecretaryCentre(entity.secretaryCentreId)
          // Add hcAccess in secretary
          user?.secretaryCentres?.forEach((secretary) =>
            secretary?._id === secretaryCentre?._id ? (secretary.centre.hcAccess = secretaryCentre?.hcAccess) : null,
          )

          setDefaultCentre(secretaryCentre.centre)
          const currentCentre = await setCurrentCentre(secretaryCentre.centre)
          dispatch(getCurrentCentre(currentCentre))
        }

      if (entity?.role === 'patient' || entity?.role === 'doctor_no_centre') {
        dispatch(getDoctorProfile(user.email))
      }

      if (entity?.role === 'centre' || entity?.role === 'admin') {
        if (entity?._id) {
          setDefaultCentre(entity?._id)
          const currentCentre = await setCurrentCentre(entity?._id)
          dispatch(getCurrentCentre(currentCentre))
        }
      }
      if (entity?.role === 'doctor' && user?.doctorCentres.length) {
        const doctorCentre = await fetchDoctorCentre(entity.doctorCentreId)
        setDefaultCentre(doctorCentre.centre._id)
        const currentCentre = await setCurrentCentre(doctorCentre.centre._id)
        dispatch(getCurrentCentre(currentCentre))
      }
      setUser(user)

      mxp.identify(user._id)

      return user
    } catch (error) {
      setUser(false)
      setDefaultCentre('')
      localStorage.removeItem('token')
      localStorage.removeItem('selectedEntity')
      return { ...error, error: true }
    }
  }

  const login = async (userCredentials) => {
    try {
      const { token } = await loginUser(userCredentials)
      localStorage.setItem('token', token)
      const response = await getUser()
      return response
    } catch (error) {
      setUser(false)
      setDefaultCentre('')
      return { error }
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('selectedEntity')
    setUser(false)
    setDefaultCentre('')
    dispatch(resetApp())
  }

  const register = async (data, patientCreatedId, role = 'patient') => {
    const userData = {
      dni: data.dni,
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      mobile: data.mobile,
      birth: data.birth,
      password: data.password,
      patient: patientCreatedId,
      roles: [role],
      //falta insurance
    }

    try {
      let userProfile = await postUser(userData)

      const auxPatient = {
        _id: patientCreatedId,
        dni: data?.dni,
        email: data?.email,
        firstname: data.firstname,
        lastname: data.lastname,
        mobile: data.mobile,
        birth: data.birth,
        //falta insurances
      }
      userProfile.patient = auxPatient
      localStorage.setItem('token', userProfile.token)
      const entity = {
        _id: null,
        role: 'patient',
      }
      const auxEntity = JSON.stringify(entity)
      localStorage.setItem('selectedEntity', auxEntity)

      delete userProfile.token
      setUser(userProfile)
      return userProfile
    } catch (error) {
      console.log(error.message)
      showToast('Error al crear el usuario. Ya existe un usuario con el DNI/email ingresado', 'error', 7000)
    }
  }

  const registerNewCentreUser = async (data, centreCreatedId, role = 'centre', onlyCreateUser = false) => {
    const userData = {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      password: data.password,
      roles: [role],
      centres: [centreCreatedId],
    }

    try {
      let userProfile = await postUser(userData)

      if (!onlyCreateUser) {
        localStorage.setItem('token', userProfile.token)
        const entity = {
          _id: centreCreatedId,
          role: role,
        }
        const auxEntity = JSON.stringify(entity)
        localStorage.setItem('selectedEntity', auxEntity)

        delete userProfile.token
        setUser(userProfile)
      }

      showToast('Registro exitoso', 'success')
      return userProfile
    } catch (error) {
      console.log(error.message)
      showToast('Error al registrar usuario', 'error')
    }
  }

  const updateUser = async (data, userId) => {
    try {
      const userUpdated = await putUser(data, userId)
      setUser(userUpdated)
      if (userUpdated) {
        showToast('Usuario actualizado correctamente', 'success')
      }
      return userUpdated
    } catch (error) {
      console.log(error.message)
    }
  }

  const value = useMemo(
    () => ({ user, defaultCentre, login, logout, register, updateUser, registerNewCentreUser, syncUser }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, defaultCentre],
  )
  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('This hook only can used into the UserContext')
  }
  return context
}
