import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { RESET_ONLINE_TURNS } from '@/store/turns'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { yupResolver } from '@hookform/resolvers/yup'

import dayjs from 'dayjs'
import { postPatient } from '../../store/patients/actions'
import { checkAvailabilityAndCreateOrUpdateTurn } from '../../store/turns/actions'
import { showToast } from '../../utils/toast'
import { ContainerForm } from '../Form/ContainerForm'
import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormRegister = ({ formValues }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register } = useUser()

  const { entityData, turnData, day, selectedService, extraServices } = useSelector((state) => state.turns?.onLineTurns)

  const [isLoading, setIsLoading] = useState(false)
  const [showErrorEmails, setShowErrorEmails] = useState(false)
  const [showErrorPasswords, setShowErrorPasswords] = useState(false)
  const [requestError, setRequestError] = useState(false)

  const handleCreatePendingTurn = async (userConected, entity, turnData, day, selectedService, extraServices) => {
    const selectedServicePrice = Number(selectedService?.price) || 0
    const extraServicesPrice = extraServices?.reduce((a, b) => Number(a) + (Number(b.price) || 0), 0)
    const amountTurn = selectedServicePrice + extraServicesPrice

    let dataTurn = {
      centre: entity?.centre,
      dni: userConected?.dni,
      creatorDni: userConected?.dni,
      firstTime: selectedService?._id === 'firstConsultation' ? true : false,
      service: ['consultation', 'firstConsultation'].includes(selectedService?._id) ? null : selectedService,
      origin: 'patient',
      startHour: dayjs(day.concat(turnData?.startHour)).utc(true).toISOString(),
      finishHour: dayjs(day.concat(turnData?.finishHour)).utc(true).toISOString(),
      turnDuration: turnData?.turnDuration,
      consultationDuration: turnData?.turnDuration,
      amount: amountTurn,
      state: 'pending',
    }

    if (extraServices?.length > 0) {
      dataTurn.extraServices = extraServices
    }

    if (entity?.name) {
      dataTurn.machine = entity
    } else {
      dataTurn.doctorCentre = entity
    }

    try {
      const validateAndCreateOrUpdateTurn = await dispatch(checkAvailabilityAndCreateOrUpdateTurn(dataTurn))

      if (validateAndCreateOrUpdateTurn) {
        navigate(`/turnos/info-paciente/${entity?.centre}`)
      } else {
        dispatch(RESET_ONLINE_TURNS())
        navigate(`/centro/${entity?.centre}`)
      }
    } catch (error) {
      console.log(error)
      showToast('Error al crear el turno', 'error')
    }
  }

  const handleSubmit = async (data) => {
    if (data?.insurance?._id === '-' || !data?.insurance) data.insurance = '-'

    if (data.email !== data.repiteEmail) {
      setShowErrorEmails(true)
      return
    } else {
      setShowErrorEmails(false)
    }

    if (data.password !== data.repitePassword) {
      setShowErrorPasswords(true)
      return
    } else {
      setShowErrorPasswords(false)
    }

    data.birth = data.birth ? data.birth : ''

    let dataToCreatePatient = { ...data }
    delete dataToCreatePatient.repiteEmail
    delete dataToCreatePatient.repitePassword

    try {
      setIsLoading(true)
      const createdPatient = await dispatch(postPatient(dataToCreatePatient))

      if (createdPatient) {
        await register(data, createdPatient?._id)
        setIsLoading(false)
        if (entityData && turnData && day && selectedService) {
          mxp.track(mxp.events.centreLanding.steps.registerDone)
          await handleCreatePendingTurn(createdPatient, entityData, turnData, day, selectedService, extraServices)
        } else {
          navigate(`/turnos`)
        }
      } else {
        setRequestError(true)
        setIsLoading(false)
      }
    } catch (error) {
      setRequestError(true)
      setIsLoading(false)
    }
  }

  return (
    <ContainerForm
      className={s.containerForm}
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues, ...formValues }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent
        isLoading={isLoading}
        showErrorEmails={showErrorEmails}
        showErrorPasswords={showErrorPasswords}
        requestError={requestError}
        setRequestError={setRequestError}
      />
    </ContainerForm>
  )
}

export default FormRegister
