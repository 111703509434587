import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import GlobalStyle from '@/assets/styles/globalStyles'
import CentreBlockPopUp from '@/components/CentreBlockPopUp'
import LoadingCalu from '@/components/LoadingCalu'
import NewVersionAvailablePopUp from '@/components/NewVersionAvailablePopUp'
import NoMercadoPagoConnectionPopUp from '@/components/NoMercadoPagoConnectionPopUp/NoMercadoPagoConnectionPopUp'
import SocketProvider from '@/context/SocketProvider'
import UserProvider from '@/context/UserProvider'
import UserNotifications from '@/pages/UserNotifications'
import Router from '@/router'
import { blockedCentreAction } from '@/store/centres/actions'
import { fetchMercadoPagoSubscription } from '@/store/mercadoPago/actions'
import ThemeMui from '@/themes/ThemeMui'
import ThemeStyledComponents from '@/themes/ThemeStyledComponents'
import { initializeDayjs } from '@/utils/dayjs'
import { initSentry } from '@/utils/sentry'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import dayjs from 'dayjs'

initSentry()
initializeDayjs()

const App = () => {
  const dispatch = useDispatch()

  const { isPageLoading } = useSelector((state) => state.app)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { blockedCentre } = useSelector((state) => state.centres)

  const [dataMercadoPagoSubscription, setDataMercadoPagoSubscription] = useState(null)
  const [openNoMercadoPagoConnectionPopUp, setOpenNoMercadoPagoConnectionPopUp] = useState(false)
  const [noMercadoPagoConnection, setNoMercadoPagoConnection] = useState(false)

  const isMobile = /Mobile/.test(navigator.userAgent)

  const diffDaysSinceCreationAccount = (currentDate, creationDate) => {
    let current = dayjs(currentDate).format('YYYY-MM-DD')
    const creation = dayjs(creationDate).format('YYYY-MM-DD')
    const diff = dayjs(current).diff(creation, 'day')
    return diff
  }

  const getMPInfo = async () => {
    const subscriptionFetch = await dispatch(
      fetchMercadoPagoSubscription({
        centreId: centreDetail?._id,
        mercadoPagoPreapprovalPlanId: centreDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanId,
      }),
    )

    if (subscriptionFetch) {
      setDataMercadoPagoSubscription(subscriptionFetch)
    }
  }

  const checkExpiration = () => {
    const savedDate = localStorage.getItem('noMercadoPagoConnectionModal')
    let itemExpired = true

    if (savedDate) {
      const expirationTime = 168 * 60 * 60 * 1000 // 7 dias en milisegundos
      const currentDate = new Date()
      const savedDateObj = new Date(savedDate)
      const difference = currentDate - savedDateObj

      if (difference >= expirationTime) {
        localStorage.removeItem('noMercadoPagoConnectionModal')
        itemExpired = true
      } else {
        itemExpired = false
      }
    }

    return itemExpired
  }

  useEffect(() => {
    let currentDate = dayjs()
    let daysSinceCreationAccount = diffDaysSinceCreationAccount(currentDate, centreDetail?.creationDate)
    let TOTAL_DAYS_FREE_TRIAL = 8

    if (daysSinceCreationAccount >= TOTAL_DAYS_FREE_TRIAL && centreDetail?.status === 'demo') {
      dispatch(blockedCentreAction({ status: true, info: 'demo' }))
      return
    }

    if (centreDetail?.status === 'blocked') {
      dispatch(blockedCentreAction({ status: true, info: 'blocked' }))
      return
    }

    if (centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription') {
      getMPInfo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreDetail, dispatch])

  useEffect(() => {
    if (!dataMercadoPagoSubscription) return

    setNoMercadoPagoConnection(
      centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription' &&
        (!dataMercadoPagoSubscription ||
          JSON.stringify(dataMercadoPagoSubscription) === '{}' ||
          dataMercadoPagoSubscription?.status === 'cancelled'),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMercadoPagoSubscription])

  useEffect(() => {
    if (noMercadoPagoConnection && checkExpiration()) {
      setOpenNoMercadoPagoConnectionPopUp(true)
    }
  }, [noMercadoPagoConnection])

  return (
    <ThemeMui>
      <ThemeStyledComponents>
        <GlobalStyle isMobile={isMobile} />
        {isPageLoading ? <LoadingCalu /> : null}
        <UserProvider>
          <SocketProvider>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale()}>
                <ToastContainer newestOnTop pauseOnFocusLoss={false} />
                <BrowserRouter>
                  {blockedCentre?.status && <CentreBlockPopUp />}
                  {noMercadoPagoConnection && (
                    <NoMercadoPagoConnectionPopUp
                      open={openNoMercadoPagoConnectionPopUp}
                      setOpen={setOpenNoMercadoPagoConnectionPopUp}
                      centreDetail={centreDetail}
                    />
                  )}
                  <NewVersionAvailablePopUp />
                  <Router />
                  <UserNotifications />
                </BrowserRouter>
              </LocalizationProvider>
            </StyledEngineProvider>
          </SocketProvider>
        </UserProvider>
      </ThemeStyledComponents>
    </ThemeMui>
  )
}

export default App
