import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { CustomMobileField } from '@/components/Form/customFields/CustomMobileField/index.jsx'
import { DateField } from '@/components/Form/customFields/DateField'
import Root from '@/components/RootDivider'
import { countryCodes, genders } from '@/helpers/constants'
import useValidateURLId from '@/hooks/useValidateURLId'
import { fetchAbsentPatientTurns } from '@/services/turns'
import { absentPatientTurns } from '@/utils/functions/absentPatientTurns'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { hasValidDni } from '@/utils/functions/hasValidDni'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Grid } from '@mui/material'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'

import s from './styles.module.scss'

const PatientDataForm = ({ showAllForm }) => {
  const centreID = useValidateURLId()
  const { t } = useTranslation('global')

  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)
  const allPatientTags = useSelector((state) => state.patientTags.all)
  const { selectedTurn } = useSelector((state) => state.turns)
  const centre = useSelector((state) => state.centres.centreProfile)

  const [dniLabel, setDniLabel] = useState('DNI')
  const [countryCode, setCountryCode] = useState('54')
  const [absentPercentage, setAbsentPercentage] = useState(null)

  const { field: countryField } = useController({ name: 'patientCentre[country]', defaultValue: 'ARG' })
  const { field: genderField } = useController({ name: 'patientCentre[gender]' })
  const { field: dniField } = useController({ name: 'patientCentre[dni]' })
  const { field: emailField } = useController({ name: 'patientCentre[email]' })
  const cancelledInfo = selectedTurn?.cancelledInfo
  const patientDni = selectedTurn?.patientCentre?.dni
  const state = selectedTurn?.state
  const optionalDni = centreConfiguration?.enableOptionalDniOnPatients
  const defaultCountry = centre?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const countryObj = countryCodes?.filter(
    (c) => c.value === (selectedTurn?.patientCentre?.country || countryField?.value?.value || defaultCountry),
  )[0]

  useEffect(() => {
    if (selectedTurn && centre?.showPatientAbsent) {
      getAbsentTurnsPercentage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTurn, centre])

  useEffect(() => {
    if (dniField?.value?.length === 15) {
      dniField.onChange('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (emailField?.value === null) {
      emailField.onChange('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    countryField.onChange(countryObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryObj])

  useEffect(() => {
    const genderObj = genders?.filter((g) => g.value === genderField?.value)
    genderField.onChange(genderObj[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genders])

  useEffect(() => {
    const countryCode = handleSetCountryCode(countryField?.value?.value || defaultCountry)
    const dniLabel = handleSetDniLabel(countryField?.value?.value || defaultCountry)
    if (countryCode) setCountryCode(countryCode)
    if (dniLabel) setDniLabel(dniLabel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryField.value])

  const cancelledTurnBy = () => {
    if (cancelledInfo === 'doctor') {
      return 'profesional'
    } else {
      return `${t(`subCategoryLabel.${getCentreSubCategory(centre?.category)}.patient`)}`
    }
  }

  const getAbsentTurnsPercentage = async () => {
    const params = {
      centre: centreID,
      patientCentre: selectedTurn?.patientCentre?._id,
    }
    const absentPercentage = await fetchAbsentPatientTurns(params)
    return setAbsentPercentage(absentPercentage)
  }

  return (
    <Grid container className={s.gridContainerPatientDataForm}>
      <div className={hasValidDni(optionalDni, patientDni) ? s.itemInfoCreatedTurn1 : s.itemInfoCreatedTurn2}>
        {hasValidDni(optionalDni, patientDni) && (
          <div className={s.alertDanger}>
            Algunas de las funcionalidades se encuentran deshabilitadas porque el{' '}
            {t(`subCategoryLabel.${getCentreSubCategory(centre?.category)}.patient`)} no tiene DNI cargado
          </div>
        )}

        <div className={s.divCreatedBy}>
          {selectedTurn?.creationDate ? (
            <div>
              <span>Creado por </span>
              <b>{`${capitalizeFirstLetters(selectedTurn?.createdBy)} ${toCustomTz(
                selectedTurn.creationDate,
                'utc',
                true,
                'DD/MM/YYYY HH:mm',
              )}hs`}</b>
            </div>
          ) : null}
          {selectedTurn?.modificatedBy ? (
            <div>
              <span>Modificado por </span>
              <b>{`${capitalizeFirstLetters(selectedTurn?.modificatedBy)} ${toCustomTz(
                selectedTurn.modificationDate,
                'utc',
                true,
                'DD/MM/YYYY HH:mm',
              )}hs`}</b>
            </div>
          ) : null}
        </div>
      </div>

      <div className={s.div1Container}>
        <div className={s.divItem}>
          <Root>
            <Divider>
              <Chip label="Datos personales" />
            </Divider>
          </Root>
        </div>
      </div>

      <div className={s.patientDataContainer}>
        {cancelledInfo && state === 'cancelled' && (
          <div className={s.cancelledInfo}>{`Turno cancelado por ${cancelledTurnBy()}`}</div>
        )}
      </div>

      {absentPercentage && (
        <div className={s.absentTurns}>{`Ausentismo: ${absentPercentage?.absentism * 100}% (${absentPatientTurns(
          absentPercentage,
        )})`}</div>
      )}

      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <CustomField
          name="patientCentre[dni]"
          label={dniLabel === 'DI' ? 'DNI, CC, CI' : dniLabel}
          onKeyDown={handleKeyDown}
          type="text"
          className={s.inputWidth90}
        />
      </Grid>

      <Grid item xs={12} sm={4} className={s.marginTextFields} style={{ display: 'flex' }}>
        {!selectedTurn?.patientCentre && (
          <div className={s.divIconX}>
            <b className={s.iconX}>❌</b>
          </div>
        )}
        <CustomField
          name="patientCentre[lastname]"
          label="Apellido(s)"
          onKeyDown={handleKeyDown}
          type="text"
          className={s.inputWidth90}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>

      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <CustomField
          name="patientCentre[firstname]"
          label="Nombre(s)"
          onKeyDown={handleKeyDown}
          type="text"
          className={s.inputWidth90}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>

      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <CustomField
          name="patientCentre[email]"
          label="Email"
          type="email"
          onKeyDown={handleKeyDown}
          className={s.inputWidth90}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>

      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <CustomMobileField
          selectedTurn={selectedTurn || {}}
          centre={centre}
          name="patientCentre[mobile]"
          countryCode={countryCode}
        />
      </Grid>

      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <CustomField
          name="patientCentre[telephone]"
          type="number"
          label="Teléfono fijo"
          onWheel={(e) => e.target.blur()}
          onKeyDown={handleKeyDown}
          className={s.inputWidth98}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <CustomField
          name="patientCentre[note]"
          type="text"
          label={`Nota del ${t(`subCategoryLabel.${getCentreSubCategory(centre?.category)}.patient`)}`}
          multiline
          onWheel={(e) => e.target.blur()}
          onKeyDown={handleKeyDown}
          className={s.inputWidth90}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <AutoCompleteField
          name="patientCentre[country]"
          label="País"
          options={sortArrayAlphabetically(countryCodes)}
          multiple={false}
          className={s.inputWidth98}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={s.marginTextFields}>
        <AutoCompleteField
          name="patientCentre[gender]"
          label="Sexo"
          options={genders}
          multiple={false}
          className={s.inputWidth98}
        />
      </Grid>

      <Grid item xs={12} className={s.marginTextFields}>
        <AutoCompleteField
          name="patientCentre[patientTags]"
          label="Etiquetas"
          options={allPatientTags}
          multiple={true}
          className={s.inputWidth98}
        />
      </Grid>
      {showAllForm && (
        <Grid container className="div1">
          <Grid item xs={12} sm={4} className={s.marginTextFields}>
            <CustomField
              name="patientCentre[address]"
              type="text"
              label="Dirección"
              onWheel={(e) => e.target.blur()}
              onKeyDown={handleKeyDown}
              className={s.inputWidth90}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={s.marginTextFields}>
            <CustomField
              name="patientCentre[city]"
              type="text"
              label="Ciudad"
              onWheel={(e) => e.target.blur()}
              onKeyDown={handleKeyDown}
              className={s.inputWidth90}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={s.marginTextFields}>
            <DateField
              name="patientCentre[birth]"
              label="Fecha de nacimiento"
              variant="standard"
              className={s.datePicker}
            />
          </Grid>
          <Grid item xs={12} className={s.marginTextFields}>
            <CheckboxField
              name="blockOnlineTurns"
              label={`Bloquear turnos online`}
              checkedLabel={`Bloqueado`}
              uncheckedLabel={'Habilitado'}
              inverted={true}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default PatientDataForm
