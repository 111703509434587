import {
  addNewSecretaryCentre,
  deleteSecretaryCentre,
  fetchSecretaryCentre,
  fetchSecretaryCentreByInputSearch,
  fetchSingleSecretaryCentre,
  putSecretaryCentre,
} from '@/services/secretaryCentre'
import { RESET_DOCTOR_CENTRE_SELECTED } from '@/store/doctorCentres'
import { SECRETARY_SELECTED } from '@/store/myProfile'
import {
  ALL_SECRETARIES_PAGINATION,
  FETCH_ALL_SECRETARIES,
  RESET_SECRETARY_CENTRE_SELECTED,
  SECRETARY_CENTRE_SELECTED,
} from '@/store/secretaryCentre'
import { showToast } from '@/utils/toast'

const getSecretaryCentre = (centre) => async (dispatch) => {
  try {
    const secretaryCentre = await fetchSecretaryCentre(centre)
    dispatch(FETCH_ALL_SECRETARIES(secretaryCentre))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getSecretaryCentreByInputSearch = (params) => async (dispatch) => {
  const auxParams = {
    ...params,
    paginate: true,
  }
  try {
    const { docs: allSecretaries, ...paginationData } = await fetchSecretaryCentreByInputSearch(auxParams)
    dispatch(ALL_SECRETARIES_PAGINATION({ allSecretaries, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postSecretaryCentre = (data) => async () => {
  try {
    const newSecretaryCentre = await addNewSecretaryCentre(data)

    if (newSecretaryCentre) {
      showToast('Asistente agregado correctamente', 'success', undefined)
    }
    return newSecretaryCentre
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const deleteSecretaryAdded = (id) => async () => {
  try {
    await deleteSecretaryCentre(id)
    showToast('Asistente eliminado correctamente', 'success', 3000)

    return id
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateSecretaryCentre = (data, addInMyProfile) => async (dispatch) => {
  try {
    const secretaryUpdated = await putSecretaryCentre(data)

    if (addInMyProfile) {
      dispatch(SECRETARY_SELECTED(secretaryUpdated))
    }
    showToast('Asistente actualizado correctamente', 'success', 3000)
    return secretaryUpdated
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedSecretaryCentre = (secretarySelectedID) => async (dispatch) => {
  try {
    const singleSecretaryCentre = await fetchSingleSecretaryCentre(secretarySelectedID)
    await dispatch(RESET_DOCTOR_CENTRE_SELECTED())
    dispatch(SECRETARY_CENTRE_SELECTED(singleSecretaryCentre))

    return singleSecretaryCentre
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedSecretaryCentre = () => async (dispatch) => {
  try {
    dispatch(RESET_SECRETARY_CENTRE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteSecretaryAdded,
  getSecretaryCentre,
  getSecretaryCentreByInputSearch,
  postSecretaryCentre,
  resetSelectedSecretaryCentre,
  selectedSecretaryCentre,
  updateSecretaryCentre,
}
